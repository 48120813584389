<template>
  <div class="Course clearfix">
    <div class="content">
      <div class="txt">
        <div class="line"></div>
        <h3>钢琴课程</h3>
        <div class="bottom-line"></div>
      </div>
      <div class="friend">
        <p>热爱音乐的朋友</p>
      </div>
      <div class="list">
        <div class="box">
          <img src="../../../assets/img/friend1.png" alt="" />
          <p>希望弹唱热门流行歌曲</p>
        </div>
        <div class="box">
          <img src="../../../assets/img/friend2.png" alt="" />
          <p>希望通过音乐娱乐放松、广结好友</p>
        </div>
        <div class="box">
          <img src="../../../assets/img/friend3.png" alt="" />
          <p>希望掌握专业的钢琴演奏技巧</p>
          <p>和声乐技巧</p>
        </div>
      </div>
      <div class="txt" style="margin-top: 20px">
        <div class="line" style="width: 200px"></div>
        <h3 style="width: auto">你是否遇到过</h3>
        <div class="bottom-line" style="width: 200px; padding-bottom: 0"></div>
      </div>
      <div class="encounter clearfix">
        <div class="top-txt">
          <div class="left-txt">
            <span class="titleBoos">Q1</span>
            <span class="info" style="margin-left: -40px;">不认识琴键、不认识谱子</span>
          </div>
          <div class="right-txt">
            <span class="titleBoos">Q2</span>
            <span class="info" style="margin-left: 20px;">左右手僵硬，配合不协调</span>
          </div>
        </div>
        <div class="top-txt" style="margin-top: 8px;">
          <div class="left-txt">
            <span class="titleBoos">Q3</span>
            <span class="info" style="margin-left: -40px;">拍子掌握不好，唱歌没情感</span>
          </div>
          <div class="right-txt">
            <span class="titleBoos">Q4</span>
            <span class="info" style="margin-left: 20px;">没有专业老师指导，无法掌握弹唱要领</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Course {
  width: 100%;
  height: 936px;
  background: url(../../../assets/img/CourseBg.png) no-repeat center;
  background-size: cover;
  .content {
    width: 1000px;
    height: auto;
    margin: 60px auto;
    h3 {
      width: 138px;
      font-size: 32px;
      font-weight: bold;
      color: #851d29;
      margin: 10px 0;
    }
    .line,
    .bottom-line {
      width: 140px;
      height: 1px;
      border-bottom: 2px solid #871a26;
    }
    .bottom-line {
      margin-bottom: 20px;
    }
    .friend {
      p {
        font-size: 28px;
        font-weight: 500;
        color: #333333;
      }
    }
    .list {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      margin: 20px auto;
      .box {
        text-align: center;
        img {
          width: 317px;
          height: 228px;
          padding-bottom: 20px;
        }
      }
    }
    .encounter {
      width: 100%;
      height: 207px;
      background: url(../../../assets/img/encounterBg.png) no-repeat center;
      background-size: cover;
      .top-txt {
        width: 100%;
        height: 70px;
        margin-top: 33px;
        display: flex;
        justify-content: space-between;
        .left-txt,
        .right-txt {
          width: 40%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          .titleBoos {
            font-size: 35px;
            font-weight: bold;
            color: #ffffff;
            background: linear-gradient(180deg, #ffffff 0%, #fff7a3 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .info {
            font-size: 18px;
            font-weight: 500;
            color: #ffffff;
            background: linear-gradient(180deg, #ffffff 0%, #fff7a3 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        .right-txt{
            justify-content: flex-start;
        }
      }
    }
  }
}
</style>    