<template>
  <div class="worry clearfix">
    <div class="content">
      <div class="txt">
        <div class="line"></div>
        <h3>担心学不会</h3>
        <div class="bottom-line"></div>
      </div>
      <div class="chat clearfix">
        <div class="left-chat">
          <div class="topBg">
            <p>担心没有基础，学不会？</p>
          </div>
          <div class="center-txt">
            <div class="left">
              <p>班主任老师</p>
              <p>助教老师</p>
            </div>
            <div class="right">
              <h3>全程答疑</h3>
            </div>
          </div>
          <div class="yesOrNo">
            <p>担心没时间，不合适？</p>
          </div>
          <div class="foot clearfix">
            <p>课程是直播+录播的形式</p>
          </div>
        </div>
        <div class="right-box">
          <div class="title">
            <span>时间灵活</span>
            <p>&nbsp;根据自己的时间自由学习</p>
          </div>
          <div class="list">
            <div class="box">
              <img src="../../../assets/img/phone.png" alt="" />
              <p>手机</p>
            </div>
            <div class="box">
              <img src="../../../assets/img/ipad.png" alt="" />
              <p>平板</p>
            </div>
            <div class="box">
              <img src="../../../assets/img/mac.png" alt="" />
              <p>电脑</p>
            </div>
          </div>
          <div class="btns">随时随地 反复观看</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.worry {
  .content {
    width: 1000px;
    height: auto;
    margin: 60px auto;
    h3 {
      width: 160px;
      font-size: 32px;
      font-weight: bold;
      color: #851d29;
      margin: 10px 0;
    }
    .line,
    .bottom-line {
      width: 160px;
      height: 1px;
      border-bottom: 2px solid #871a26;
    }
    .bottom-line {
      margin-bottom: 20px;
    }
    .chat {
      width: 1000px;
      height: auto;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .left-chat {
        width: 311px;
        height: auto;
        margin-top: 20px;

        .topBg {
          width: 311px;
          height: 105px;
          background: url(../../../assets/img/chat1.png) no-repeat center;
          background-size: cover;
          text-align: center;
          padding-bottom: 10px;
          p {
            font-size: 20px;
            font-weight: 800;
            color: #ffffff;
            line-height: 80px;
          }
        }
        .center-txt {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          padding-bottom: 10px;
          p {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
          }
          h3 {
            font-size: 28px;
            font-weight: 800;
            color: #851d29;
          }
        }
        .yesOrNo {
          width: 280px;
          height: 60px;
          background: url(../../../assets/img/chat2.png) no-repeat center;
          background-size: cover;
          text-align: center;
          padding-bottom: 10px;
          p {
            font-size: 20px;
            font-weight: 800;
            color: #ffffff;
            line-height: 65px;
          }
        }
        .foot {
          text-align: center;
          margin-top: 20px;
        }
      }
      .right-box {
        width: 457px;
        height: 330px;
        background: #851d29;
        border-radius: 24px;
        .title {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          height: 100px;
          color: white;
          span {
            font-weight: 800;

            font-size: 22px;
          }
          p {
            font-size: 20px;
          }
        }
        .list {
          margin: 0 auto;
          width: 90%;
          height: auto;
          text-align: center;
          color: white;
          display: flex;
          justify-content: space-between;
          img {
            padding-bottom: 10px;
          }
        }
        .btns {
          margin: 7px auto;
          width: 221px;
          height: 47px;
          border-radius: 24px;

          background: #333333;
          color: white;
          text-align: center;
          line-height: 47px;
          font-weight: bold;

        }
      }
    }
  }
}
</style>