<template>
  <div class="harvest clearfix">
    <div class="content">
      <div class="txt">
        <div class="line"></div>
        <h3>您将收获</h3>
        <div class="bottom-line"></div>
      </div>
      <div class="list">
        <div class="box">
          <img src="../../../assets/img/harvest1.png" alt="" />
          <div class="txt">
            <h3>乐理知识学习</h3>
            <p>融会贯通乐理知识，</p>
            <p>告别不会弹琴的困局</p>
          </div>
        </div>
        <div class="box">
          <img src="../../../assets/img/harvest2.png" alt="" />
          <div class="txt">
            <h3>弹唱技巧巩固</h3>
            <p>合理运用弹奏技巧，</p>
            <p>快速提升弹唱水平</p>
          </div>
        </div>
        <div class="box">
          <img src="../../../assets/img/harvest3.png" alt="" />
          <div class="txt">
            <h3>热门名曲练习</h3>
            <p>学习弹奏世界名曲，</p>
            <p>演绎动听旋律</p>
          </div>
        </div>
        <div class="box">
          <img src="../../../assets/img/harvest4.png" alt="" />
          <div class="txt">
            <h3>身心健康保持</h3>
            <p>陶冶情操、提升气质、</p>
            <p>吐纳气息、颐养身心</p>
          </div>
        </div>
        <div class="box">
          <img src="../../../assets/img/harvest5.png" alt="" />
          <div class="txt">
            <h3>线上好友结交</h3>
            <p>结交同好好友，告别</p>
            <p>孤独，丰富老年生活</p>
          </div>
        </div>
      </div>
      <div class="jiaocheng">
        <img src="../../../assets/img/jiaocheng.png" alt="" />
        <div class="txt">
          <p>《钢琴弹唱必学基础教程》</p>
          <p>电子版</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.harvest {
  width: 100%;
  height: auto;
  background: #e4e4e4;
  .content {
    width: 1000px;
    height: auto;
    margin: 0px auto;
    margin-top: 60px;
    h3 {
      width: 138px;
      font-size: 32px;
      font-weight: bold;
      color: #851d29;
      margin: 10px 0;
    }
    .line,
    .bottom-line {
      width: 140px;
      height: 1px;
      border-bottom: 2px solid #871a26;
    }
    .bottom-line {
      margin-bottom: 20px;
    }
    .friend {
      p {
        font-size: 28px;
        font-weight: 500;
        color: #333333;
      }
    }
    .list {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-evenly;
      .box {
        width: 191px;
        display: flex;
        height: auto;
        flex-wrap: wrap;
        justify-content: center;
        img {
          width: 191px;
          height: 147px;
        }
        h3 {
          font-size: 20px;
          font-weight: bold;
          color: #831925;
        }
        p {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
        }
      }
    }
    .jiaocheng {
      width: 700px;
      height: 227px;
      margin: 50px auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
      img {
        width: 340px;
        height: 227px;
      }
      .txt {
        font-size: 24px;
        font-weight: bold;
        color: #831925;
      }
    }
  }
}
</style>