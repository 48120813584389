<template>
  <div class="receive clearfix">
    <div class="content">
      <div class="txt">
        <div class="line"></div>
        <h3>如何领课</h3>
        <div class="bottom-line"></div>
      </div>
      <div class="box">
        <div class="left-arrow">
          <p>扫一扫，识别二维码，</p>
          <p>添加老师微信，等待上课</p>
        </div>
        <div class="right-wecaht">
          <img src="../../../assets/img/accountWeachat.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.receive {
  width: 100%;
  height: 697px;
  background: url(../../../assets/img/receiveBg.png) no-repeat center;
  background-size: cover;
  .content {
    width: 1000px;
    height: auto;
    margin: 60px auto;
    h3 {
      width: 138px;
      font-size: 32px;
      font-weight: bold;
      color: #851d29;
      margin: 10px 0;
    }
    .line,
    .bottom-line {
      width: 140px;
      height: 1px;
      border-bottom: 2px solid #871a26;
    }
    .bottom-line {
      margin-bottom: 20px;
    }
    .box {
      width: 710px;
      height: auto;
      margin: 70px auto;
      align-items: center;
      display: flex;
      justify-content: space-between;
      .left-arrow {
        width: 375px;
        height: 170px;
        background: url(../../../assets/img/arrowLeft.png) no-repeat center;
        background-size: cover;
        color: white;
        text-align: left;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        p {
          margin-left: -120px;
        }
      }
      .right-wecaht {
        width: 243px;
        height: 249px;
        border: 3px solid #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>