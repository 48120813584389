<template>
  <div class="banner clearfix">
    <div class="content">
      <div class="title">
        <h3>0基础学钢琴弹唱网课</h3>
      </div>
      <div class="zhaomu">
        <p>招募<span>热爱弹琴唱歌</span>的朋友免费学</p>
      </div>
      <div class="mengxiang">
        <p>实现音乐梦想</p>
      </div>
      <div class="checked">
        <div class="box">
          <img src="../../../assets/img/check.png" alt="" />
          <span>快速入门 </span>
        </div>
        <div class="box">
          <img src="../../../assets/img/check.png" alt="" />
          <span>名师授课 </span>
        </div>
        <div class="box">
          <img src="../../../assets/img/check.png" alt="" />
          <span>直播授课 </span>
        </div>
        <div class="box">
          <img src="../../../assets/img/check.png" alt="" />
          <span>全程陪练</span>
        </div>
      </div>
      <div class="btn" v-show="false">
        <span>¥ 0元免费领</span>
      </div>
      <div class="userInfo">
        <h3>一洋老师</h3>
        <p>艺途国际钢琴学院院长</p>
        <p>著名音乐人、青年编剧</p>
        <p>本科毕业于北京师范大学</p>
        <p>研究生毕业于香港大学</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "banner",
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  height: 660px;
  background: url(../../../assets/img/Pianobanner.png) no-repeat center;
  background-size: cover;
  margin-top: 70px;
  .content {
    width: 48%;
    height: 400px;
    margin: 120px auto;
    position: relative;
    .title {
      height: 65px;
      h3 {
        width: 517px;
        height: 65px;
        font-size: 50px;
        font-weight: bold;
        color: #ffffff;
        line-height: 75px;
        letter-spacing: 3px;
        background: linear-gradient(180deg, #ffffff 0%, #fff7a3 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .userInfo {
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
      height: auto;
      writing-mode: vertical-lr;
      color: white;
      h3 {
        font-size: 29px;
        font-weight: bold;
      }
      p {
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
  .zhaomu {
    width: 572px;
    height: 64px;
    background: url(../../../assets/img/zmBg.png) no-repeat center;
    background-size: cover;
    margin-left: -20px;
    margin-top: 51px;

    text-align: center;
    span {
      color: #c61a1a;
    }
    p {
      height: 28px;
      font-size: 22px;
      font-weight: 500;
      color: #330000;
      line-height: 65px;
    }
  }
  .mengxiang {
    width: 200px;
    height: 40px;
    margin: 20px 0 10px 100px;
    text-align: right;
    line-height: 40px;
    color: white;
  }
  .checked {
    width: 400px;
    height: 40px;

    margin-left: 60px;
    display: flex;
    line-height: 40px;
    justify-content: space-around;
    span {
      font-size: 14px;
      color: white;
    }
  }
  .btn {
    width: 235px;
    height: 64px;
    margin: 40px 0;
    background: url(../../../assets/img/btn.png) no-repeat center;
    background-size: cover;
    text-align: center;
    line-height: 64px;
    span {
      font-size: 28px;
      font-weight: bold;
      color: #ffffff;
      background: linear-gradient(180deg, #ffffff 0%, #fff7a3 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
</style>