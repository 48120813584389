<template>
    <div class="piano">
        <title-yt></title-yt>
        <nav-top></nav-top>
        <banner/>
        <teachers-power/>
        <course/>
        <class-section/>
        <harvest/>
        <worry/>
        <receive/>
        <copyright></copyright>
    </div>
</template>

<script>
import Copyright from '../../pubilic/copyright.vue'
import NavTop from '../../pubilic/NavTop.vue'
import TitleYt from '../../pubilic/TitleYt.vue'
import Banner from './Banner.vue'
import TeachersPower from './TeachersPower.vue'
import Course from './Course.vue'
import ClassSection from './ClassSection.vue'
import Harvest from './harvest.vue'
import Worry from './worry.vue'
import Receive from './receive.vue'

export default {
  components: { TitleYt, NavTop, Banner,  Copyright, TeachersPower, Course, ClassSection,Harvest, Worry, Receive},
   
}
</script>

<style>

</style>