<template>
  <div class="TeachersPower clearfix">
    <div class="content">
      <div class="left-txt">
        <div class="line"></div>
        <h3>师资力量</h3>
        <div class="bottom-line"></div>
        <div class="name"><h4>一洋老师</h4></div>
        <div class="info">
          <p>艺途国际钢琴学院院长</p>
          <p>著名音乐人、青年编剧</p>
          <p>本科毕业于北京师范大学</p>
          <p>研究生毕业于香港大学</p>
          <p>4岁学琴，10岁考取全国音乐家协会钢琴十级</p>
          <p>2011年内蒙古高考艺考状元，师从于丹</p>
          <p>曾任职知名音乐教育公司高级教师，专注专研成人钢琴教学</p>
          <p>体系，培养出数百万粉丝博主“乌云奶奶”等学生。</p>
          <p>
            编剧作品《因为爱情有多美》《因为爱情有晴天》《因为爱情有奇迹》《因为爱
          </p>
          <p>情有奇缘》，在CCTV、湖南卫视金鹰</p>
          <p>独播剧场获全国收视第一。</p>
          <p>曾作为特邀表演嘉宾参加中央电视台五四青年文艺晚会</p>
          <p>曾担任内蒙古电视台、内蒙古广播电视台特邀表演嘉宾</p>
        </div>
        <div class="music-icon">
          <img src="../../../assets/img/musicIcon.png" alt="" />
        </div>
      </div>
      <div class="right-img">
        <div class="head-title">
          <h3>YITU</h3>
        </div>
        <div class="img">
            <img src="../../../assets/img/szllTeacher.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.TeachersPower {
  width: 100%;
  height: auto;
  height: 650px;
  .content {
    width: 1000px;
    height: auto;
    margin: 60px auto;
    display: flex;
    .left-txt {
      width: 50%;
      height: auto;
      h3 {
        width: 138px;
        font-size: 32px;
        font-weight: bold;
        color: #851d29;
        margin: 10px 0;
      }
      .line,
      .bottom-line {
        width: 140px;
        height: 1px;
        border-bottom: 2px solid #871a26;
      }
      .bottom-line {
        margin-bottom: 20px;
      }
      .name {
        h4 {
          width: 116px;
          font-size: 28px;
          font-weight: 500;
          color: #831925;
          line-height: 42px;
        }
      }
      .info {
        padding-bottom: 30px;
        p {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
        }
      }
    }
    .right-img {
      width: 50%;
      margin-top: -50px;
      margin-left: 95px;
      .head-title {
        font-size: 170px;
        font-weight: 800;
        color:#DEDEDE;
        line-height: 288px;
        letter-spacing: 10px;
      }
      .img{
        position: relative;
        top: -160px;
        left: 75px;
        img{
            width: 380px;
            object-fit: cover;
        }
      }
    }
  }
}
</style>