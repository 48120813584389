<template>
  <div class="ClassSection clearfix">
    <div class="content">
      <div class="txt">
        <div class="line"></div>
        <h3>八大课节</h3>
        <div class="bottom-line"></div>
      </div>
      <div class="friend">
        <p>带你快速入门钢琴/电子琴等键盘乐器</p>
      </div>
      <div class="piano clearfix">
        <div class="top-btn">
          <div class="box"><p>第一部分</p></div>
          <div class="box"><p>第二部分</p></div>
          <div class="box"><p>第三部分</p></div>
          <div class="box"><p>第四部分</p></div>
        </div>
        <div class="line">
          <div class="radio"></div>
          <div class="radio"></div>
          <div class="radio"></div>
          <div class="radio"></div>
        </div>
        <div class="eight-txt">
          <div class="txt">
            <p>1.学习钢琴常用弹奏技法</p>
            <p>2.学习识谱，根据简谱弹奏</p>
          </div>
          <div class="txt">
            <p>3.学习音乐基础知识</p>
            <p>4.学习弹唱《茉莉花》</p>
          </div>
          <div class="txt">
            <p>5.学习和声、和弦以及搭配</p>
            <p>6.带你完整演奏世界名曲</p>
          </div>
          <div class="txt">
            <p>7.钢琴学习中常见问题及</p>
            <p>针对性日常练习</p>
            <p>8.综合能力提高及答疑</p>
          </div>
        </div>
        
      </div>
      <div class="txt" style="margin-top: 50px;">
        <div class="line"></div>
        <h3>课程安排</h3>
        <div class="bottom-line"></div>
      </div>
      <div class="Curriculum">
        <img src="../../../assets/img/Curriculum.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.ClassSection {
  .content {
    width: 1000px;
    height: auto;
    margin: 60px auto;
    h3 {
      width: 138px;
      font-size: 32px;
      font-weight: bold;
      color: #851d29;
      margin: 10px 0;
    }
    .line,
    .bottom-line {
      width: 140px;
      height: 1px;
      border-bottom: 2px solid #871a26;
    }
    .bottom-line {
      margin-bottom: 20px;
    }
    .friend {
      padding-bottom: 20px;
      p {
        font-size: 28px;
        font-weight: 500;
        color: #831925;
        -webkit-background-clip: text;
      }
    }
    .piano {
      width: 100%;
      height: 518px;
      background: url("../../../assets/img/ClassSectionPiano.png") no-repeat
        center;
      background-size: cover;
      .top-btn {
        width: 873px;
        height: 61px;
        margin: 0 auto;
        margin-top: 70px;
        display: flex;
        justify-content: space-around;
        .box {
          width: 188px;
          height: 61px;
          background: #831925;
          border-radius: 31px;
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            font-size: 20px;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
      .line {
        width: 873px;
        height: 2px;
        border-bottom: 4px solid #cb0a00;
        margin: 25px auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .radio {
          width: 27px;
          height: 27px;
          background: #333333;
          border-radius: 50%;
        }
      }
      .eight-txt {
        width: 900px;
        height: auto;
        margin: 40px auto;
        display: flex;
        justify-content: space-around;
      }
    }
    .Curriculum{
        img{
            width: 975px;
            height: 626px;
        }
    }
  }
}
</style>